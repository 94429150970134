<template>
  <div class="bg-ligtGary">
    <TopNav/>

    <div class="header header-cart">
      <div class="w w1200">
        <div class="logo">
          <div class="logoImg">
            <router-link :to="{path:'/'}">
              <img :src="require('@/assets/co/' + co + '/logo.png')">
            </router-link>
          </div>
          <div class="tit">购物车（<em>{{sumNums}}</em>）</div>
        </div>
        <div class="dsc-search">
          <div class="form">
            <input autocomplete="off" v-model="keywords" type="text" class="search-text">
            <input type="hidden" name="store_search_cmt" value="0">
            <button type="submit" class="button button-icon" v-on:click="search"><i></i></button>

            <div class="suggestions_box" id="suggestions" style="display:none;">
              <div class="suggestions_list" id="auto_suggestions_list">
                &nbsp;
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="w w1200" v-loading="cartLoading" element-loading-text="拼命加载中......">
        <div class="cart-empty" v-if="cartList.length <= 0">
          <div class="cart-message">
            <div class="txt">购物车快饿瘪了，主人快给我挑点宝贝吧</div>
            <div class="info">
              <router-link :to="{path:'/'}" class="btn sc-redBg-btn" tag="a">马上去逛逛</router-link>
            </div>
          </div>
        </div>
        <div class="cart-warp" v-else>
          <div class="cart-filter">
            <div class="cart-stepflex">
              <div class="cart-step-item">
                <span>1.我的购物车</span>
                <i class="iconfont icon-arrow-right-alt"></i>
              </div>
              <div class="cart-step-item curr">
                <span>2.填写订单信息</span>
                <i class="iconfont icon-arrow-right-alt"></i>
              </div>
              <div class="cart-step-item">
                <span>3.成功提交订单</span>
              </div>
            </div>
            <div class="sp-area store-selector">

            </div>
          </div>

          <div class="checkout-warp">
            <div class="ck-step" id="consignee_list">
              <div class="ck-step-tit">
                <h3>收货人信息</h3>
                <!--
                <a href="user.php?act=address_list" class="extra-r" target="_blank">管理收货人地址</a>
                -->
              </div>
              <div class="ck-step-cont" id="consignee-addr">
                <div class="consignee-warp">
                  <div :class="'cs-w-item '+(item.defaultMark == 1 ?' cs-selected':'')" v-for="(item,idx) in consigneeList" :key="idx" @click.stop="consigneeSet(item)">
                    <div class="item-tit">
                      <h3 class="username">{{item.name}}</h3>
                      <span class="remark"></span>
                    </div>
                    <div class="item-tel">
                      <span class="contact">{{item.mobile}}</span>
                    </div>
                    <div class="item-address">{{item.province}} {{item.city}} {{item.area}} {{item.address}}</div>
                    <i class="icon"></i>
                    <a href="javascript:void(0);" class="edit" ectype="dialog_checkout" @click.stop="(addressEditShow(item.id))">修改</a>
                    <!--
                    <a href="javascript:void(0);" class="delete" ectype="dialog_checkout">删除</a>
                    -->
                  </div>
                  <div class="cs-w-item">
                    <a href="javascript:void(0);" class="add-new-address" @click="addressEditShow('')">
                      <i class="iconfont icon-add-quer"></i><span>添加新地址</span>
                    </a>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>

            <!--
            <div class="ck-step checkout">
              <div class="ck-step-tit">
                <h3>支付方式</h3>
              </div>
              <div class="ck-step-cont">
                <div class="payment-warp">
                  <div class="payment-list" ectype="paymentType">
                    <div class="p-radio-item payment-item item-selected">
										<span>在线支付</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            -->
            <div class="ck-step">
              <div class="ck-step-tit">
                <h3>商品信息</h3>
                <router-link class="extra-r" :to="{path:'/cart'}">返回购物车</router-link>
              </div>
              <div class="ck-step-cont">
                <div class="ck-goods-warp" id="goods_inventory">
                  <div class="ck-goods-list">
                    <div class="ck-goods-item" ectype="shoppingList">
                      <!--
                      <div class="ck-goods-tit">
                        <div class="ck-store">
                          <a href="javascript:;" class="shop-name">自营</a>
                          <a href="javascript:;" id="IM" onclick="openWin(this)" ru_id="0" class="p-kefu  p-c-violet" target="_blank"><i class="iconfont icon-kefu"></i></a>
                        </div>
                        <div class="ck-dis-modes">
                          <div class="ck-dis-tit">配送方式：</div>
                          <div class="ck-dis-info" ectype="disInfo">
                            <ul class="ck-sp-type">
                              <li class="mode-tab-item mode-tab-log shopping-list-checked item-selected" ectype="tabLog" data-ruid="0" data-type="0" data-shipping="9" data-shippingcode="sto_express">
                                <span>申通快递</span>
                              </li>
                            </ul>
                            <div class="mwapper mwapper-logistics" ectype="logistics">
                              <i class="i-box"></i>
                              <div class="mwapper-content">
                                <ul>
                                  <li class="logistics_li item-selected" data-ruid="0" data-type="0" data-shipping="9" data-shippingcode="sto_express" data-text="申通快递">
                                    <span>申通快递</span>
                                    <em>（免运费）</em>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="mwapper mwapper-since" ectype="since">
                              <i class="i-box"></i>
                              <div class="mwapper-content">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      -->
                      <div class="ck-goods-cont">
                        <div class="cg-item last" v-for="(good) in cartList" v-bind:key="good.cartId">
                          <div class="c-col cg-name">
                            <router-link :to="{path:'/goods',query:{id:good.goodsId}}" target="_blank" tag="a">
                              <div class="p-img"><img :src="getCoverImage(good.images)" width="48" height="48"></div>
                              <div class="p-info">
                                <div class="p-name">{{good.goodsName}}</div>
                                <div class="sales-promotion" v-if="good.objectName" style="font-size: 12px">
                                  {{good.objectName}}
                                </div>
                              </div>
                            </router-link>
                          </div>
                          <div class="c-col cg-price">
                            <span class="fr cl"><em>¥</em>{{good.price}}</span>
                          </div>
                          <div class="c-col cg-number">x{{good.qty}}</div>
                          <!--
                          <div class="c-col cg-state">
                            <span>有货</span>
                          </div>
                          -->
                          <div class="c-col cg-sum"><em>¥</em>{{good.price * good.qty}}</div>
                          <div class="cg-item-line"></div>
                          <i class="dian"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ck-order-remark">
                  <input name="postscript" type="text" v-model="memo" maxlength="45" size="15" class="text" placeholder="订单备注 限60个字" autocomplete="off" onblur="if(this.value==''||this.value=='订单备注 限60个字'){this.value='订单备注 限60个字';this.style.color='#cccccc'}" onfocus="if(this.value=='订单备注 限60个字') {this.value='';};this.style.color='#666';">
                  <span class="prompt">提示：请勿填写有关支付、收货、发票方面的信息</span>
                </div>
              </div>
            </div>
            <!--
            <div class="ck-step">
              <div class="ck-step-tit">
                <h3>发票信息</h3>
                <div class="tips-new-white">
                  <b></b><span><i></i>开企业抬头发票须填写纳税人识别号，以免影响报销</span>
                </div>
              </div>
              <div class="ck-step-cont" id="inv_content">
                <div class="invoice-warp">
                  <div class="invoice-part">
                    <span>
                      <em class="invoice_type">普通发票（纸质）</em>
                      <em class="inv_payee">个人</em>
                      <em class="inv_content"></em>
                    </span>
                    <a href="javascript:void(0);" class="i-edit" ectype="invEdit">编辑</a>
                  </div>
                </div>
              </div>
            </div>
            -->
            <!--
            <div class="ck-step">
              <div class="ck-step-tit">
                <h3>其他信息</h3>
              </div>
              <div class="ck-step-cont">
                <div class="other-warp">
                  <div class="other-list">
                    <div class="qt_item">
                      <div class="item_label">缺货处理：</div>
                      <div class="item_value">
                        <div class="radio-item">
                          <input type="radio" name="how_oos" class="ui-radio" value="0" checked="" id="checkbox_0" onclick="changeOOS(this)" autocomplete="off">
                          <label for="checkbox_0" class="ui-radio-label">等待所有商品备齐后再发</label>
                        </div>
                        <div class="radio-item">
                          <input type="radio" name="how_oos" class="ui-radio" value="1" id="checkbox_1" onclick="changeOOS(this)" autocomplete="off">
                          <label for="checkbox_1" class="ui-radio-label">取消订单</label>
                        </div>

                        <div class="radio-item">
                          <input type="radio" name="how_oos" class="ui-radio" value="2" id="checkbox_2" onclick="changeOOS(this)" autocomplete="off">
                          <label for="checkbox_2" class="ui-radio-label">与店主协商</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            -->
          </div>

          <div>
            <div class="order-summary">
              <div class="statistic">
                <div class="list">
                  <span><em>{{numberAmount}}</em> 件商品，总商品金额：</span>
                  <em class="price" id="warePriceId"><em>¥</em>{{ goodsAmount }}</em>
                </div>
                <div class="list">
                  <span>邮费：</span>
                  <em class="price" id="warePriceId"><em>¥</em>{{ freightAmount }}</em>
                </div>
                <div class="list">
                  <span>应付总额：</span>
                  <em class="price-total"><em>¥</em>{{ goodsAmount+freightAmount }}</em>
                </div>
              </div>
            </div>

            <div class="checkout-foot" ectype="tfoot-toolbar">
              <div class="w w1200">
                <div class="btn-area">
                  <el-button class="submit-btn" @click.native="submitCart" :loading="submitLoading">提交订单</el-button>
                </div>
                <!--
                <div class="d-address">
                  <span id="sendAddr">寄送至：江苏常州郊区常州</span>
                  <span id="sendMobile">收货人：曹海峰&nbsp;&nbsp;13961152999</span>
                </div>
                -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w w1200">
        <guess-love cssName="p-panel-main c-history" :count="6"></guess-love>
      </div>
    </div>

    <Footer/>
    <addressEdit ref="addressEdit" v-if="addressEditVisible" @close="getAddress"></addressEdit>
  </div>
</template>
<script>
import Footer from "@/components/footer/Index";
import GuessLove from "@/components/GuessLove/Index";
import TopNav from "@/components/TopNav/Index";
import {getCartList} from "@/api/user";
import {getAddressList, updateDefaultMark} from "@/api/address";
// eslint-disable-next-line no-unused-vars
import {orderCreate, cal} from "@/api/mall/order";
import addressEdit from "@/views/member/address/edit.vue"
import { mapGetters } from 'vuex'

export default {
  name: "Cart",
  data() {
    return {
      cartList:[],
      sumNums: 0,
      keywords: '',
      cartLoading:false,
      submitLoading:false,
      memo:'',  //备注

      consigneeInfo: null,
      consigneeList: [],

      freightAmount: 0,   // 总运费
      numberAmount: 0,    // 商品总数
      goodsAmount: 0,     // 商品总金额

      addressEditVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'cartSelected'
    ])
  },
  components: {
    Footer,
    GuessLove,
    TopNav,
    addressEdit
  },
  created() {
    this.getCartList()

  },
  watch: {
    consigneeInfo: {
      handler:function(){
        this.calSum()
      },
      deep:true//对象内部的属性监听，也叫深度监听
    },
  },
  methods:{
    consigneeSet(item){

      // cal({carts:this.cartList,address: this.consigneeInfo}).then(res=>{
      //   console.log('---------------')
      //   console.log(res)
      // })


      if(item.province == '' || item.province == null){
        this.$message({
          message: '地址省市区为空，请修改！',
          type: 'warning'
        });
        return;
      }

      this.consigneeList = this.consigneeList.map(function(item) {
        item.defaultMark = 0;
        return item;
      })
      item.defaultMark = 1
      this.consigneeInfo = item

      // eslint-disable-next-line no-unused-vars
      updateDefaultMark(item.id).then(_=>{});
    },
    getCartList(){
      this.cartLoading = true

      getCartList({ids:this.cartSelected}).then(res => {
        this.cartList = res.data.normal

        this.cartList.forEach((val)=>{
          if(val.paramsData!=null){
            val.params = JSON.parse(val.paramsData);
          }else{
            val.params = []
          }
        })
        this.cartLoading = false

        this.getAddress()

      })
    },
    calSum(){
      this.freightAmount = 0;
      this.numberAmount = 0;
      this.goodsAmount = 0;

      let _this = this
      this.cartList.forEach(function (val){
        _this.numberAmount = _this.numberAmount + val.qty
        _this.goodsAmount = _this.goodsAmount + val.qty * val.price
      });

      // 江浙沪皖满400包邮，不足付10元运费，其他地区满500包邮、不足付20元运费。
      // if(this.consigneeInfo){
      //   if(this.consigneeInfo.province == '江苏省' || this.consigneeInfo.province == '上海市' || this.consigneeInfo.province == '浙江省' || this.consigneeInfo.province == '安徽省'){
      //     if(this.goodsAmount < 400){
      //       this.freightAmount = 10
      //     }
      //   }else{
      //     if(this.goodsAmount < 500){
      //       this.freightAmount = 20
      //     }
      //   }
      // }
    },
    submitCart(){
      this.submitLoading = true
      let _this = this

      if(this.consigneeInfo === undefined){
        this.$message({
          message: '请选择或填写收货地址',
          type: 'warning'
        })
        this.submitLoading = false
        return
      }

      if(this.cartList.length > 0){
        orderCreate({
          carts : this.cartList,
          note : this.memo,
          buyer : this.consigneeInfo
        }).then(function(res){
          if(res.code == 200){
            _this.$router.push({path:'/cart/success',query:{id:res.data}});
          }else{
            _this.$message({
              message: res.msg,
              type: 'success'
            });
          }
          // eslint-disable-next-line no-unused-vars
        }).catch(function (err) {
          _this.submitLoading = false
        });
      }else{
        _this.submitLoading = false
      }
    },
    search(){
      let routerJump = this.$router.resolve({ path: '/search', query: { 'keywords':this.keywords } })
      window.open(routerJump.href, '_blank')
    },
    getAddress: function () {
      let _this = this;
      getAddressList().then(function(res){
        _this.consigneeList = res.data
        var defualtList = _this.consigneeList.filter(w => w.defaultMark == 1)

        // _this.consigneeInfo = defualtList[0]
        if(defualtList && defualtList.length > 0){
          _this.consigneeInfo = defualtList[0]

        }

        // 如果都没有默认地址，还是选第一个
        if(defualtList && defualtList.length <= 0){
          _this.consigneeInfo = _this.consigneeList[0]
          _this.consigneeInfo.defaultMark = 1
          // eslint-disable-next-line no-unused-vars
          updateDefaultMark(_this.consigneeInfo.id).then(_=>{});

        }
      }).catch(function (err) {
        console.log(err)
      });
    },
    getCoverImage(images){
      let json = JSON.parse(images)
      return json[0].attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + json[0].imageUrl
    },

    addressEditShow(id){
      this.addressEditVisible = true
      this.$nextTick(() => {
        this.$refs.addressEdit.init(id)
      })
    }
  }
}
</script>

<style>
.item-form .s-quantity .buy-num {
  width: 36px !important;
}
</style>
<style scoped>
.submit-btn{line-height:20px !important;border-radius: 0;}
.submit-btn:hover,.submit-btn:focus {background-color: #f42424;color: #fff;}

.ck-order-remark{
  margin-top: 45px;
  height: 52px;
}
.ck-order-remark .text{
  float: none;
  margin-left: 18px;
}
</style>
