<template>
  <el-dialog title="编辑地址"
             :close-on-click-modal="false"
             :visible.sync="visible"
             class="dev-dialog dev-dialog-center"
             lock-scroll width="800px" append-to-body>
    <el-form ref="ruleForm" :model="form" label-width="176px" :rules="rules">
      <el-form-item label="收件人姓名" prop="name">
        <el-input v-model="form.name" placeholder="请输入收件人姓名"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="mobile">
        <el-input v-model="form.mobile" placeholder="请输入联系方式"></el-input>
      </el-form-item>
      <el-form-item label="联系地址" prop="addressList">
        <el-cascader v-model="form.addressList" :options="district" :props="{ expandTrigger: 'hover' }" style="width: 100%"></el-cascader>
      </el-form-item>
      <el-form-item label="详细地址" prop="address">
        <el-input type="textarea" placeholder="请输入详细地址" v-model="form.address"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">关闭</el-button>
      <el-button type="primary" @click="submit">保存</el-button>
    </span>
  </el-dialog>
</template>
<script>
import district from "@/assets/mall/district.json";
import {create, getInfo, update} from "@/api/address";
export default {
  name: "DistrictSelect",
  data() {
    return {

      visible: false,

      district: [],
      form: {
        addressList: [],
        name: '',
        mobile: '',
        address: '',
        id: null
      },
      rules: {
        address: [{ required: true, message: '请输入详细地址信息', trigger: 'blur' }],
        addressList: [{ required: true, message: '请选择收货地址', trigger: 'change' }],
        name: [{ required: true, message: '请输入收件人姓名', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入联系方式', trigger: 'blur' }]
      }
    }
  },
  props: {
    value: {},
  },
  mounted() {
    // let id = this.$getQueryVariable('id');
    // if (id) {
    //   this.getDetail(parseInt(id));
    // } else {
      this.getList(district);
      this.district = district;
    // }
  },
  methods: {
    getList: function (district) {
      for (let i = 0; i < district.length; i++) {
        district[i].label = district[i].name;
        district[i].value = district[i].name;
        if (district[i].list) {
          district[i].children = district[i].list;
          this.getList(district[i].children);
        }
      }
    },
    init(id) {
      this.visible = true
      if(id){
        getInfo(id).then(res => {
          this.form = res.data
          if(res.data.province && res.data.city && res.data.area){
            this.form.addressList = [res.data.province, res.data.city, res.data.area]
          }
        })
      }
    },
    submit() {
      this.form.province = this.form.addressList[0]
      this.form.city = this.form.addressList[1]
      this.form.area = this.form.addressList[2]

      const formMethod = this.form.id ? update : create
      // eslint-disable-next-line no-unused-vars
      formMethod(this.form).then(res => {
        this.$message({
          message: this.form.id ? '修改成功！' : '新增成功！',
          type: 'success'
        });
        this.visible = false
        this.$emit('close');
      })
    }
  }
}
</script>
<style scoped>

</style>