import request from '@/utils/request'

export function orderCreate(data) {
    return request({
        url: '/app/mall/order',
        method: 'POST',
        needToken: true,
        data
    })
}

// export function getOrderList(params){
//     return request({
//         url: '/api/Order/GetOrderList',
//         method: 'post',
//         needToken: true,
//         data: params
//     })
// }

export function orderList(data) {
    return request({
        url: '/app/mall/order/list',
        method: 'POST',
        needToken: true,
        data
    })
}

export function getCountAsync(params){
    return request({
        url: '/api/Order/GetCountAsync',
        method: 'post',
        needToken: true,
        data: params
    })
}

export function cal(data) {
    return request({
        url: '/app/mall/order/cal',
        method: 'POST',
        needToken: true,
        data
    })
}
